import React from 'react'
import { Elements, HTMLSerializer } from 'prismic-reactjs'
import { Text } from '@chakra-ui/react'

// -- Function to add unique key to props
const propsWithUniqueKey = function (props: any, key: any) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
export const htmlSerializer: HTMLSerializer<React.ReactNode> = (type, element, content, children, key) => {
  var props = {}

  switch (type) {
    // Add a class to paragraph elements
    case Elements.paragraph:
      props = { className: 'paragraph-class' }
      return <Text {...propsWithUniqueKey(props, key)}>{children}</Text>

    // Don't wrap images in a <p> tag
    case Elements.image:
      props = { src: element.url, alt: element.alt || '' }
      return React.createElement('img', propsWithUniqueKey(props, key))

    // Add a class to hyperlinks
    case Elements.span: // Span
      if (content) {
        return content.split('\\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p]
          } else {
            const brIndex = (acc.length + 1) / 2 - 1
            const br = React.createElement('br', propsWithUniqueKey({}, brIndex))
            return acc.concat([br, p])
          }
        }, [])
      } else {
        return null
      }

    // Return null to stick with the default behavior
    default:
      return null
  }
}

export default htmlSerializer
